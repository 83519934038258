<template>
	<img :src="dataUrl" :code="skuData.goodsSku" :title="skuData.goodsName" ref="imgSku" class="imgSku" />
</template>

<script>
	import JsBarcode from 'jsbarcode';

	export default {
		// name: 'barcode',

		// props: ['code'],
		props: {
			// mobile:"",
			skuData: {
				default: function() {
					return {
						goodsSku: "",
						goodsName: "",
						userId: "",
					};
				},
				type: Object,
				requried: true,
			},

			option: {
				default: function() {
					return {
						// size: "3",
						// // showLogo: true,
						// showCus: false,
						// showSkuName: true,
						// addText: []
					};
				},
				type: Object,
				requried: true,
			},

			// text: {
			//   default: function() {
			//     return '';
			//   },
			//   type: String
			// },

			// options: {
			// 	default: function() {
			// 		return {
			// 			width: 400,
			// 			height: 30,
			// 			fontSize: 25
			// 		};
			// 	},
			// 	type: Object,
			// 	requried: true,
			// },
		},
		data() {
			return {
				canvas: "",
				ctx:"",
				dataUrl: ""
			};
		},
		watch: {
			skuData: function(newVal, oldVal) {
				console.log('watchKey skuData', newVal);
				this.initData();
			},
			// option: function(newVal, oldVal) {
			// 	console.log('watchKey option', newVal);
			// 	this.initData();
			// },
			option: {
				handler(newValue, oldValue) {
					console.log('watchKey option',newValue);
					this.initData();
				},
				deep: true // 启用深度监听
			}
		},
		created() {
			console.log('created');
			this.canvas = document.createElement('canvas');
			this.ctx = this.canvas.getContext('2d');
			this.initData();
		},
		mounted() {
			console.log('monted');
		},
		methods: {
			initData() {
				// if (this.text) {
				this.drawText();
				// }
			},

			async loadImage(src) {
				return new Promise((resolve, reject) => {
					const img = new Image();
					img.crossOrigin = "anonymous"; // 解决跨域问题（如果需要）
					img.src = src;

					img.onload = () => resolve(img);
					img.onerror = (err) => reject(err);
				});
			},

			async drawText() {
				// 创建canvas元素
				// var canvas = document.createElement('canvas');
				var canvas = this.canvas;
				var ctx = this.ctx;
				
				let formatSize = [];
				if ('1' == this.option.size) {
					formatSize = [150, 50];
				} else if ('2' == this.option.size) {
					formatSize = [150, 80];
				} else if ('3' == this.option.size) {
					formatSize = [150, 100];
				} else if ('4' == this.option.size) {
					formatSize = [200, 100];
				} else if ('5' == this.option.size) {
					formatSize = [300, 100];
				} else if ('6' == this.option.size) {
					formatSize = [400, 100];
				} else {
					formatSize = [150, 100];
				}
				
				// 设置画布大小
				// canvas.width = this.option.width || 500; // 设置宽度为500像素
				// canvas.height = this.option.height || 300; // 设置高度为300像素
				canvas.width = formatSize[0]*2.8; // 设置宽度为500像素
				canvas.height = formatSize[1]*2.8; // 设置高度为300像素

				let topMarigin = 20;
				let leftMargin = 10;

				let codeWidth = 300;
				let codeHeight = 130;
				
				// 清空画布
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				// 绘制背景
				ctx.fillStyle = "#fff";
				ctx.fillRect(0, 0, canvas.width, canvas.height);
				
				//自提黑色
				ctx.fillStyle = "#000";
				// 加载并绘制图片

				const canvas_code = document.createElement("canvas"); // 创建 canvas
				JsBarcode(canvas_code, this.skuData.goodsSku, {
					// format: "CODE128",
					// displayValue: true, // 显示文本
					font: 'Arial',

					fontSize: 30,
					// fontSize: 18,
					// lineColor: "#000",
					// width: 2,
					height: codeHeight - 30,
					// width:2,
					// height:100,
					// background: "#fff"
				});
				// this.barcodeSrc = canvas.toDataURL("image/png"); // 转换为 Base64 并赋值

				// const codeimg = new Image();
				// codeimg.src = canvas_code.toDataURL("image/png");
				// console.log('src',codeimg.src)
				
				let pageWidth = canvas.width;
				
				const codeimg = await this.loadImage(canvas_code.toDataURL("image/png"));
				
				let width = codeimg.width<100?100:codeimg.width;
				console.log('codeimg width',width)
				let leftMarngin_center = 0;
				leftMarngin_center = parseFloat((pageWidth-parseFloat(width))/2);
				if(leftMarngin_center>5){
					leftMargin = parseFloat(leftMarngin_center.toFixed(2));
				}

				// codeimg.onload = () => {
				// 	console.log('onload');
				ctx.drawImage(codeimg, leftMargin, topMarigin, width, codeHeight);
				// 导出图片
				// const image = canvas.toDataURL("image/png");
				// const link = document.createElement("a");
				// link.href = image;
				// link.download = "canvas-image.png";
				// link.click();

				// 绘制内容到画布上（这里只是一个示例）
				// ctx.fillStyle = 'red';
				// ctx.fillRect(10, 10, 480, 280);

				// };
				
				let base_fontStyle = "px Arial,Helvetica,sans-serif";
				//SKU文字
				//sku名称
				if (this.option.showSkuName) {
					// ctx.font = this.options.fontSize+"px serif";
					ctx.font = "25" + base_fontStyle;
					// ctx.textBaseline = "alphabetic";
					ctx.fillText(this.skuData.goodsName, leftMargin, topMarigin+ codeHeight  + 20);
				}
				
				//客户ID
				if (this.option.showCus) {
					ctx.font = "14" + base_fontStyle;
					ctx.fillText(this.skuData.userId, leftMargin, topMarigin + codeHeight + 20 + 20 + 20);
					// let userId = barCodeItem.find('.code_user').text();
					// pdf.setFontSize(10);
					// pdf.text(leftMargin, topMargin + 60 + 10, userId);
				}
				
				console.log('addText',this.option.addText);
				//附加文字
				if (this.option.addText && this.option.addText.length > 0) {
					// let addText_list = barCodeItem.find('.code_mark').find('span');
					// pdf.setFontSize(10);
					ctx.font = "14" + base_fontStyle;
					if (this.option.addText.includes('New')) {
						ctx.fillText('New', leftMargin, topMarigin + codeHeight + 20 + 20);
						// pdf.text(leftMargin, topMargin + 60 + 10 + 10, addText_list.eq(0).text());
					}
					if (this.option.addText.includes('Made in China')) {
						//居左距离
						let right_left_margin = width-100;
						ctx.fillText('Made in China', leftMargin + right_left_margin, topMarigin + codeHeight + 20 + 20 + 20);
						// pdf.text(leftMargin + 100, topMargin + 60 + 10 + 10, addText_list.eq(1).text());
					}
					console.log('addText',this.option.addText);
					// console.log('ctx',ctx);
				}

				// 将画布转换为DataURL格式的图片数据
				this.dataUrl = canvas.toDataURL();
				// console.log('dataUrl',this.dataUrl);
				// console.log(dataUrl);
			},
			// getBarCode(value) {
			// 	this.$nextTick(() => {
			// 		try {
			// 			let barcode_ref = this.$refs.barcode;
			// 			JsBarcode(barcode_ref, value, this.options);
			// 		} catch (e) {
			// 			console.log(e);
			// 			this.$message.warning(this.$t('i18nn_6b3c6618251b6db5'));
			// 		}
			// 	});
			// }
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>